.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;

  .alert {
    position: relative;
    background-color: #fff;
    padding: 2em;
    margin: auto;

    p {
      font-size: 1.6em;
    }

    .close {
      border-radius: 100%;
      background-color: #000;
      width: 2em;
      height: 2em;
      cursor: pointer;
      position: absolute;
      top: -1em;
      right: -1em;
    }

    &.error p {
      color: #c00;
    }
  }
}
