@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500');
@import 'comments-form';
@import 'comments-listing';
@import 'alert';
@import 'user-profile';

* {
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 62.5%;
  padding: 0;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 0.5em;
  font-size: 1.6em;
}

button {
  font-size: 1.6em;
  font-weight: bold;
  padding: 0.5em 1em;
  cursor: pointer;
  text-align: left;
  border-radius: 0.2em;
  border: 0 none;

  &.facebook {
    background-color: #3b5998;
    background-image: url(/images/facebook.svg);
    background-position: 0.5em 50%;
    background-repeat: no-repeat;
    background-size: auto 65%;
    padding-left: 2.4em;
    color: #fff;
    border: 0 none;
  }

  &.google {
    background-color: #4c8bf5;
    background-image: url(/images/google.svg);
    background-position: 0.5em 50%;
    background-repeat: no-repeat;
    background-size: auto 65%;
    padding-left: 2.4em;
    color: #fff;
    border: 0 none;
  }

  &.apple {
    background-color: #000;
    background-image: url(/images/apple.svg);
    background-position: 0.5em 50%;
    background-repeat: no-repeat;
    background-size: auto 65%;
    padding-left: 2.4em;
    color: #fff;
    border: 0 none;
  }
}
