.user-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.8em;

  p {
    font-size: 1.2em;
    margin-right: 0.5em;
  }

  button {
    font-size: 1.2em;
    padding: 0.2em 0.5em;
    font-weight: normal;
  }
}
