.form {
  text-align: center;
  margin-bottom: 2em;

  .row {
    display: flex;
    width: calc(100% + 0.8em);
    margin-left: -0.4em;
    margin-bottom: 0.8em;

    > * {
      margin: 0 0.25em;
    }
  }

  .rating {
    margin-bottom: 0.8em;
  }

  button {
    margin: 0 0 0.25em 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    max-width: 20em;
  }

  @media (min-width: 600px) {
    button {
      width: auto;
      margin: 0 0.25em;
    }
  }
}

.rating {
  display: flex;

  .star {
    display: block;
    width: 2em;
    height: 2em;
    padding-right: 0.4em;
    position: relative;
    background-image: url(/images/star.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    &.active {
      background-image: url(/images/star_active.svg);
    }
  }

  &.enabled .star {
    cursor: pointer;
  }
}
