.comment {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #eee;

  header {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 1.4em;
    }

    .user {
      display: flex;
      align-items: center;

      .rating {
        margin-left: 1em;
        font-size: 0.6em;
      }
    }

    .date {
      color: #999;
    }
  }

  p {
    font-size: 1.6em;
  }
}

p.empty {
  text-align: center;
  font-size: 1.6em;
}
